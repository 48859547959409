<template>
  <div>
    <v-img width="100%" height="100%" :src="item" @click="openImage(item)" />
    <v-dialog v-model="dialog" max-width="600px">
      <v-carousel height="100%" v-if="items && items.length" v-model="selectedIndex" hide-delimiter-background
        delimiter-icon="mdi-minus">
        <v-carousel-item v-for="(image, index) in items" :key="index">
          <v-img :src="image">
            <template v-slot:placeholder>
              <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
            </template>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: false
    },
    item: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      selectedIndex: 0
    };
  },
  methods: {
    openImage(image) {
      this.selectedIndex = this.items.indexOf(image);
      this.dialog = true;
    }
  }
};
</script>